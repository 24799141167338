var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.classNames,attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Classement des 10 meilleurs groupes")]),_c('v-card-subtitle',[_vm._v("par rapport aux résultats de la semaine dernière")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"disable-sort":"","hide-default-footer":"","disable-pagination":"","disable-filtering":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.delta_day",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
            greenCell: item.raw_delta_day > 0,
            redCell: item.raw_delta_day < 0,
          }},[_vm._v(" "+_vm._s(item.delta_day)+" ")])]}},{key:"item.delta_week",fn:function(ref){
          var item = ref.item;
return [_c('div',{class:{
            greenCell: item.raw_delta_week > 0,
            redCell: item.raw_delta_week < 0,
          }},[_vm._v(" "+_vm._s(item.delta_week)+" ")])]}},{key:"item.delta_month",fn:function(ref){
          var item = ref.item;
return [_c('div',{class:{
            greenCell: item.raw_delta_month > 0,
            redCell: item.raw_delta_month < 0,
          }},[_vm._v(" "+_vm._s(item.delta_month)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }