<template>
  <v-row>
    <v-col class="pl-0 ml-n2">
      <v-autocomplete
        v-model="selectedItem"
        item-text="name"
        item-value="id"
        :items="items"
        label="Metrique"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        outlined
        dense
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "IndicatorFilter",
  data() {
    return {};
  },
  async created() {
    const items = await this.$store.dispatch("audience/getAcpmIndicatorList");

    const defaultSelected = items.find((i) => i.name === "visites");
    this.$store.dispatch(
      "audience/updateAcpmIndicator",
      defaultSelected?.id ?? ""
    );
  },
  computed: {
    items() {
      return this.$store.getters["audience/getAcpmIndicatorList"];
    },
    selectedItem: {
      get() {
        return this.$store.getters["audience/getAcpmIndicator"];
      },
      set(id) {
        this.$store.dispatch("audience/updateAcpmIndicator", id);
      },
    },
  },
};
</script>

<style scoped></style>
