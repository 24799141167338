<template>
  <v-row>
    <v-col class="pl-0 ml-n2">
      <v-autocomplete
        v-model="selectedItems"
        item-text="name"
        item-value="id"
        :items="items"
        label="Site"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        multiple
        outlined
        dense
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SiteFilter",
  data() {
    return {};
  },
  async created() {
    await this.$store.dispatch("audience/getAcpmSiteList");
  },
  computed: {
    items() {
      return this.$store.getters["audience/getAcpmSiteList"];
    },
    selectedItems: {
      get() {
        return this.$store.getters["audience/getAcpmSites"];
      },
      set(ids) {
        this.$store.dispatch("audience/updateAcpmSites", ids);
      },
    },
  },
};
</script>

<style scoped></style>
