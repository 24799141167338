<template>
  <div>
    <p class="text-2xl mb-6">ACPM - comparatif concurrence</p>
    <v-card class="mb-6 pa-2" outlined>
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <top-businesses classNames="mb-6 pa-2" />

    <top-sites classNames="mb-6 pa-2" />

    <v-row class="mb-6 pa-2">
      <v-col cols="6">
        <top-businesses-over-period />
      </v-col>
      <v-col cols="6">
        <top-sites-over-period />
      </v-col>
    </v-row>

    <v-row class="mb-6 pa-2">
      <v-col cols="6">
        <top-thematics-over-period />
      </v-col>
      <v-col cols="6">
        <top-sub-thematics-over-period />
      </v-col>
    </v-row>

    <results-table classNames="mb-6 pa-2" />
  </div>
</template>

<script>
import Filters from "@/components/audience/acpm/filters/Filters.vue";
import ResultsTable from "@/components/audience/acpm/tables/Results.vue";
import TopBusinesses from "@/components/audience/acpm/tables/TopBusinesses.vue";
import TopBusinessesOverPeriod from "@/components/audience/acpm/cards/TopBusinessesOverPeriod.vue";
import TopSites from "@/components/audience/acpm/tables/TopSites.vue";
import TopSitesOverPeriod from "@/components/audience/acpm/cards/TopSitesOverPeriod.vue";
import TopSubThematicsOverPeriod from "@/components/audience/acpm/cards/TopSubThematicsOverPeriod.vue";
import TopThematicsOverPeriod from "@/components/audience/acpm/cards/TopThematicsOverPeriod.vue";

import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "Acpm",
  components: {
    Filters,
    ResultsTable,
    TopBusinesses,
    TopBusinessesOverPeriod,
    TopSites,
    TopSitesOverPeriod,
    TopSubThematicsOverPeriod,
    TopThematicsOverPeriod,
  },
  data() {
    return {};
  },
  mounted() {
    saveRmraView(this.$route);
  },
};
</script>

<style scoped></style>
