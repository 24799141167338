<template>
  <v-card :class="classNames" outlined>
    <v-card-title>Résultats de recherche</v-card-title>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par : groupe, site, thématique ou sous-thématique)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            clearable
            v-model="search_column"
            :items="[
              { text: 'Groupe', value: 'business' },
              { text: 'Site', value: 'site' },
              { text: 'Thématique', value: 'thematic' },
              { text: 'Sous-thématique', value: 'subthematic' },
            ]"
            :menu-props="{ maxHeight: '200', zIndex: 13 }"
            label="Dans la colonne"
          ></v-select>
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        class="elevation-1"
        :page="options.page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="total"
        :sort-by="sortBy"
        :sort-desc="true"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      />
    </v-card-text>
  </v-card>
</template>

<style scoped></style>

<script>
import debounce from "lodash/debounce";
import useAxios from "@/hooks/useAxios";

import { formatNumberCompact, ucfirst } from "@/utils/formatting";

export default {
  name: "Results",
  components: {},
  props: {
    classNames: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      optionsInitialized: false,
      forceCall: false,
      items: [],
      loading: false,
      // headers: [], => computed
      numberOfPages: 0,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
      },
      sortBy: "value",
      search: "",
      search_column: "",
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    debouncedSearch: debounce(async function () {
      if (this.options.page !== 1) {
        this.options.page = 1;
        // updateData automatically call through watcher
      } else {
        this.getData();
      }
    }, 500),
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    formatResult(data) {
      return data.map((item) => {
        return {
          ...item,
          value: formatNumberCompact(item.value),
        };
      });
    },
    async getData() {
      console.log(
        "getData",
        this.applyFilters,
        this.forceCall,
        this.indicator,
        this.startDate,
        this.endDate
      );
      if (
        (this.applyFilters || this.forceCall) &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10 &&
        this.indicator
      ) {
        this.loading = true;
        const {
          data: { items, total },
        } = await this.axiosGet("/audience/acpm/data-for-period", {
          start_date: this.startDate,
          end_date: this.endDate,
          indicator: this.indicator,
          business_ids: this.businesses.join(","),
          site_ids: this.sites.join(","),
          thematic_ids: this.thematics.join(","),
          subthematic_ids: this.subThematics.join(","),
          limit: this.options.itemsPerPage,
          page: Math.max(this.options.page, 1),
          sort_by: this.options.sortBy?.[0] ?? "value",
          sort_desc: this.options.sortDesc?.[0] ?? true,
          search_text: this.search,
          search_column: this.search_column,
        });

        this.items = this.formatResult(items);

        this.total = total;
        this.numberOfPages = total / this.options.itemsPerPage;

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    indicator() {
      return this.$store.getters["audience/getAcpmIndicator"];
    },
    indicatorList() {
      return this.$store.getters["audience/getAcpmIndicatorList"];
    },
    indicatorName() {
      const indicator = this.indicatorList.find(
        (item) => item.id === this.indicator
      );

      return ucfirst(indicator?.name ?? "");
    },
    businesses() {
      return this.$store.getters["audience/getAcpmBusinesses"];
    },
    sites() {
      return this.$store.getters["audience/getAcpmSites"];
    },
    thematics() {
      return this.$store.getters["audience/getAcpmThematics"];
    },
    subThematics() {
      return this.$store.getters["audience/getAcpmSubThematics"];
    },
    headers() {
      const headers = [
        {
          text: "Groupe",
          value: "business",
        },
        {
          text: "Site",
          value: "site",
        },
        {
          text: "Thématique",
          value: "thematic",
        },
        {
          text: "Sous-thématique",
          value: "subthematic",
        },
        {
          text: "indicator",
          value: "value",
        },
      ];

      return headers.map((header) => ({
        ...header,
        text: header.text === "indicator" ? this.indicatorName : header.text,
      }));
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.debouncedGetData();
    },
    search() {
      this.forceCall = true;
      this.debouncedSearch();
    },
    search_column() {
      this.forceCall = true;
      this.debouncedSearch();
    },
  },
};
</script>
