<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="3">
        <indicator-filter />
      </v-col>
      <v-col cols="3">
        <date-filter store="audience" periodType="past-only" range="7" />
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <v-row class="d-flex">
      <v-col cols="6">
        <business-filter />
      </v-col>
      <v-col cols="6">
        <site-filter />
      </v-col>
    </v-row>
    <v-row class="d-flex">
      <v-col cols="3">
        <thematic-filter />
      </v-col>
      <v-col cols="3"></v-col>
      <v-col cols="3">
        <sub-thematic-filter />
      </v-col>
      <v-col cols="3" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";

import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import BusinessFilter from "@/components/audience/acpm/filters/BusinessFilter.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import IndicatorFilter from "@/components/audience/acpm/filters/IndicatorFilter.vue";
import SiteFilter from "@/components/audience/acpm/filters/SiteFilter.vue";
import SubThematicFilter from "@/components/audience/acpm/filters/SubThematicFilter.vue";
import ThematicFilter from "@/components/audience/acpm/filters/ThematicFilter.vue";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    BusinessFilter,
    DateFilter,
    IndicatorFilter,
    SiteFilter,
    SubThematicFilter,
    ThematicFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {},
};
</script>

<style></style>
