<template>
  <v-card :class="classNames" outlined>
    <v-card-title>Classement des 10 meilleurs sites</v-card-title>
    <v-card-subtitle
      >par rapport aux résultats de la semaine dernière</v-card-subtitle
    >
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        disable-sort
        hide-default-footer
        disable-pagination
        disable-filtering
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.delta_day="{ item }">
          <div
            :class="{
              greenCell: item.raw_delta_day > 0,
              redCell: item.raw_delta_day < 0,
            }"
          >
            {{ item.delta_day }}
          </div>
        </template>
        <template v-slot:item.delta_week="{ item }">
          <div
            :class="{
              greenCell: item.raw_delta_week > 0,
              redCell: item.raw_delta_week < 0,
            }"
          >
            {{ item.delta_week }}
          </div>
        </template>
        <template v-slot:item.delta_month="{ item }">
          <div
            :class="{
              greenCell: item.raw_delta_month > 0,
              redCell: item.raw_delta_month < 0,
            }"
          >
            {{ item.delta_month }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.greenCell {
  color: green;
}

.redCell {
  color: red;
}
</style>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";

import {
  getDate,
  getDateStr,
  getDayFr,
  getWeekNumber,
  monthsFrArray,
  weekRangeDate,
} from "@/utils/dates";
import { formatNumberCompact, formatPercent } from "@/utils/formatting";

const today = getDate();
const yesterday = getDate(-1);
const twoDaysAgo = getDate(-2);
const weekNumber = getWeekNumber();

const getPreviousMonthIndex = (monthIndex) =>
  monthIndex > 0 ? monthIndex - 1 : 11;

const currentMonthIndex = today.getMonth();
const prevMonthIndex = getPreviousMonthIndex(currentMonthIndex);
const twoMonthAgoIndex = getPreviousMonthIndex(prevMonthIndex);

const [week1_start, week1_end] = weekRangeDate(
  today.getFullYear(),
  weekNumber - 1,
  2
);
const [week2_start, week2_end] = weekRangeDate(
  today.getFullYear(),
  weekNumber - 2,
  2
);

export default {
  name: "TopSites",
  components: {},
  props: {
    classNames: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      initialized: false,
      headers: [
        {
          text: "Site",
          value: "site",
        },
        {
          text: `J-1 (${getDayFr(yesterday)})`,
          value: "day-1",
        },
        {
          text: `J-2 (${getDayFr(twoDaysAgo)})`,
          value: "day-2",
        },
        {
          text: "Evolution / journée",
          value: "delta_day",
        },
        {
          text: `S-1 (${getDateStr(week1_start)} / ${getDateStr(week1_end)})`,
          value: "week-1",
        },
        {
          text: `S-2 (${getDateStr(week2_start)} / ${getDateStr(week2_end)})`,
          value: "week-2",
        },
        {
          text: "Evolution / semaine",
          value: "delta_week",
        },
        {
          text: `M-1 (${monthsFrArray[prevMonthIndex]})`,
          value: "month-1",
        },
        {
          text: `M-2 (${monthsFrArray[twoMonthAgoIndex]})`,
          value: "month-2",
        },
        {
          text: "Evolution / mois",
          value: "delta_month",
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    formatResult(data) {
      return data.map((item) => {
        return {
          ...item,
          ["day-1"]: formatNumberCompact(item["day-1"]),
          ["day-2"]: formatNumberCompact(item["day-2"]),
          ["raw_delta_day"]: item["day-1"] - item["day-2"],
          ["delta_day"]: `${formatNumberCompact(
            item["day-1"] - item["day-2"]
          )} (${formatPercent(item["day-1"] / Math.max(1, item["day-2"]))})`,
          ["week-1"]: formatNumberCompact(item["week-1"]),
          ["week-2"]: formatNumberCompact(item["week-2"]),
          ["raw_delta_week"]: item["week-1"] - item["week-2"],
          ["delta_week"]: `${formatNumberCompact(
            item["week-1"] - item["week-2"]
          )} (${formatPercent(item["week-1"] / Math.max(1, item["week-2"]))})`,
          ["month-1"]: formatNumberCompact(item["month-1"]),
          ["month-2"]: formatNumberCompact(item["month-2"]),
          ["raw_delta_month"]: item["month-1"] - item["month-2"],
          ["delta_month"]: `${formatNumberCompact(
            item["month-1"] - item["month-2"]
          )} (${formatPercent(
            item["month-1"] / Math.max(1, item["month-2"])
          )})`,
        };
      });
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.applyFilters || this.initialized === false) &&
        !this.loading &&
        this.indicator
      ) {
        this.loading = true;
        const { data } = await this.axiosGet("/audience/acpm/top-sites", {
          indicator: this.indicator,
          business_ids: this.businesses.join(","),
          site_ids: this.sites.join(","),
          thematic_ids: this.thematics.join(","),
          subthematic_ids: this.subThematics.join(","),
          limit: 10,
        });

        this.items = this.formatResult(data);

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);

        this.initialized = true;
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    indicator() {
      return this.$store.getters["audience/getAcpmIndicator"];
    },
    businesses() {
      return this.$store.getters["audience/getAcpmBusinesses"];
    },
    sites() {
      return this.$store.getters["audience/getAcpmSites"];
    },
    thematics() {
      return this.$store.getters["audience/getAcpmThematics"];
    },
    subThematics() {
      return this.$store.getters["audience/getAcpmSubThematics"];
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    indicator() {
      this.debouncedGetData();
    },
  },
};
</script>
